.mainText{
    font-family: Poppins;
    font-size: 16px;
    width: 539px;
    color: #242851;
}
.mainContainer{
    height: 260px;
    width: 80%;
}
.contactItem {
    margin-left: 8px;
}

@media(max-width: 550px) {
    .separator {
        margin-top: 15px;
    }

    .bottomSep {
        margin-bottom: 15px;
    }
}
.emailInput{
    height: 49px;
    border-radius: 15px;
    }
.button{
    height: 49px;
    border-radius: 15px;
    background-color: #5BB3BA;
    margin-top: 8px;
    color: white;
    border: none;
    }