.logo{
    margin-right: 15px;
}
.motto{
    display: inline;
    font-size: 18px;
    font-weight: bold;
    color: #242851;
    margin-left: 10px;
}
.merchant{
    border: 2px solid #242851;
    border-radius: 7px;
    width: 178px;
}
.merchant:hover{
    width: 290px;
}
.merchantButton{
    display: inline;
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 13px;
    line-height: 26px;
    margin-left: 10px;
    color: #5BB3BA;
}
.merchantButton span{
    color: #242851;
}
.merchantButton span:hover{
    display: none;
}
@media (max-width: 765px ){

    .merchant:hover{
        width: 165px;
    }
    .merchantButton{
        font-size: 12px;
    }
}
@media (max-width: 480px){
    .motto{
        font-size: 14px;
    }
    .navbar-brand{
        margin-right: 0;
    }
    .logo{
        width: 95%;
        margin-right: 5px;
    }
    .merchant{
        width: 165px;
    }
    .merchant:hover{
        width: 165px;
    }
    .merchantButton{
        font-size: 12px;
    }
}

@media (max-width: 374px){
    /* .sepRectangle,
    .motto{
        display: none;
    } */
    .logo{
        width: 80%;
        margin-left: 10px;
       
    }

}

@media (max-width: 320px){
    .sepRectangle,
    .motto{
        display: none;
    }
    .logo{
        width: 100%;
        margin-left: 10px;
       
    }

}