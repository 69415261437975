.exp-wrapper {
    border: 1px solid #aaa;
    border-radius: 30px;
    display: flex;
    justify-content: space-around;
}

.exp-wrapper:after {
    font-size: 24px;
    content: '/';
    position: absolute;
    left: 50%;
    margin-left: -10px;
}

input.exp {
    border: 0;
    width: 20%;
    outline: none;
    appearance: none;
    font-size: 20px;
    padding-bottom: 7px;
    color: black;
}