.rateappContainer
{
    /* padding-bottom: 60px; */
    margin-top: 12px;
    height: 60px;
    
}

.radio1
{
    width:20%;
    float: left;

}