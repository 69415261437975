.hero-phone {
    width: 160%;
  }
  .mainSection{
    font-family: Poppins;
    color: #242851;
  }

.headerText{
    font-size: 60px; 
}
.headerText span{
    color: #54B7E2;
}
.codeInput{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: 2px solid #242851;
    border-radius: 7px;
    padding: 5px 0px 5px 3px;
}

#inputEmail3{
    border: none;
    padding: 0;
    margin: 0;
    width: 80%;
}
#inputEmail3:focus{
    outline: none;
}
.bottomInput{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.bottomInput i{
  margin-left: 10px;
  font-weight: bold;
}
.emailForm{
    border-radius: 7px;
}
.emailBox{    
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid #242851;
  border-radius: 7px;
}
.emailBox img{
  margin-left: 3px;
  height: 100%;
}
.emailInput{
  padding-left: 5px;
  background-color: transparent;
  border-radius: 7px;
}

.btn-search{
    border: none;
    border-radius: 7px;
    background-color: #5BB3BA;
    color: 'white',
}
.bottomInput input{
  border: none;
}
.bottomInput button{
border: none;
border-radius: 7px;
background-color: #5BB3BA;
margin-top: 8px;
color: white;
padding: 13px 5px;
}

.nowrap
{
    white-space: nowrap;
}


  @media (max-width: 1281px){
    .hero-phone{
        width: 120%;
    }
  }
  @media (max-width: 1199px){
    .hero-phone{
        width: 140%;
    }
  }
  @media (max-width: 1189px){
    .hero-phone{
        width: 130%;
    }
  }
  @media (max-width: 1025px){
    .hero-phone{
        width: 110%;
    }
  }
  @media (max-width: 990px){
    .hero-phone{
        width: 100%;
    }
  }

  @media (max-width: 768px){
    .hero-phone{
        width: 100%;
    }
    .headerText{
      font-size: 44px;
    }
  }
  @media (max-width: 450px){
    .headerText{
      font-size: 42px;
    }
  }

  @media (max-width: 394px){
    .headerText{
      font-size: 32px;
    }
  }
